<form [formGroup]="customOptionsFormGroup" [ngClass]="{ enabled: customOptionsFormGroup.value.overrideEnabled }" class="flex flex-row gap-x-6 w-full">
  <div class="self-center text-center">
    <p-checkbox inputId="enableForm" formControlName="overrideEnabled" [binary]="true" styleClass="mr-2" />
    <label class="text-sm uppercase" for="enableForm">Override</label>
  </div>
  <div class="flex-auto p-float-label">
    <input [readonly]="!customOptionsFormGroup.value.overrideEnabled" id="customMediaId" inputId="mediaIdText" formControlName="customMediaId" [required]="true" pInputText class="w-full" type="text" />
    <label for="mediaIdText">Media ID / URL</label>
  </div>
  <div class="flex-auto p-float-label">
    <p-dropdown [readonly]="!customOptionsFormGroup.value.overrideEnabled" [options]="envs" id="customEnv" formControlName="customEnv" [required]="true" optionLabel="title" optionValue="env" [autoDisplayFirst]="false" [showClear]="true" styleClass="w-full" inputId="envDropdown"></p-dropdown>
    <label for="envDropdown">Env.</label>
  </div>
  <div class="flex-auto p-float-label">
    <p-dropdown [readonly]="!customOptionsFormGroup.value.overrideEnabled" [options]="cdnOptions" formControlName="customCdn" inputId="customCdn" styleClass="w-full inputfield" [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
    <label for="customCdn">CDN</label>
  </div>
  <div class="flex-auto p-float-label">
    <input [readonly]="!customOptionsFormGroup.value.overrideEnabled" id="customAdProfile" inputId="adProfileText" formControlName="customAdProfile" pInputText class="w-full inputfield" type="text" />
    <label for="adProfileText">Ad Profile</label>
  </div>
  <div>
    <p-button [disabled]="!customOptionsFormGroup.valid" icon="pi pi-play" (onClick)="playOverride()"></p-button>
  </div>
</form>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'event-watch-yospace-stats',
  standalone: true,
  imports: [],
  template: `
    @if (streamId && drmType) {
      <iframe [src]="getEmbed(drmType + '_concurrents', streamId)" width="{{ graphOpts.width }}" height="{{ graphOpts.height }}" class="graph-frame" frameBorder="0"></iframe>
    }
  `,
  styleUrl: './event-watch-yospace-stats.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventWatchYospaceStatsComponent {
  @Input() streamId? = '';
  @Input() drmType? = '';
  graphOpts = {
    width: 500,
    height: 200,
    legend: false,
  };
  urlDict: any = {
    cbcs_concurrents: '2140c2fbcda0b3d480ee7f705674308ad6a695e5d50d2e8073af80ba943e88e4',
    cenc_concurrents: 'd3f97a1221d6f8c6070eda7e80beee38870c8c8784cd6ceead5f31b324a19bb2',
  };

  constructor(private sanitizer: DomSanitizer) {}

  getEmbed(graphName: string, streamId: string) {
    const url = `https://app.datadoghq.com/graph/embed?token=${this.urlDict[graphName]}&height=${this.graphOpts.height}&width=${this.graphOpts.width}&legend=${this.graphOpts.legend}&streamId=${streamId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

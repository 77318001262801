import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfigMap } from '@models/ClientConfig';
import { PlayerLogMessage, PlayerLogType } from '@models/PlayerLogging';
import { PlayerOptions } from '@models/PlayerOptions';
import { ConfigService } from '@services/config.service';
import { AdConfig, AuthTokenType, EngineConfig, IPlayer, ListenableEventBase, ListenableEventCallback, ListenableEvents, MediaJSONData, MediaStateChangeEvent, PlaybackConfig, PlayConfig, Player, PlayerConfigEnvType, SCTEEvent, SegmentPlaybackEvent, SetupConfig, UIConfig, UIControlName } from '@top/player-web-lite';
import { PlayerUIModule } from '@top/player-web-lite/dist/modules/player-ui/player-ui-lite';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs';
import { environment } from '../../../../environments/environment';

type PlayerEventListener = ListenableEventCallback<ListenableEventBase>;

const eventLog = (event: ListenableEventBase) => console.log(`🪵 ${event.type}`, event);
const eventInfo = (event: ListenableEventBase) => console.info(`🪵 ${event.type}`, event);
const eventWarn = (event: ListenableEventBase) => console.warn(`🪵 ${event.type}`, event);
const eventErr = (event: ListenableEventBase) => console.error(`🪵 ${event.type}`, event);

enum LogLevel {
  Log = 'log',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

const eventMap: Record<ListenableEvents, LogLevel> = {
  // ListenableEvents = BitmovinEvents | TubEvents | LitePlayerEvents

  // BitmovinEvents
  [ListenableEvents.AdBreakFinished]: LogLevel.Info,
  [ListenableEvents.AdBreakStarted]: LogLevel.Info,
  [ListenableEvents.AdClicked]: LogLevel.Log,
  [ListenableEvents.AdError]: LogLevel.Error,
  [ListenableEvents.AdFinished]: LogLevel.Log,
  [ListenableEvents.AdInteraction]: LogLevel.Log,
  [ListenableEvents.AdLinearityChanged]: LogLevel.Log,
  [ListenableEvents.AdManifestLoaded]: LogLevel.Log,
  [ListenableEvents.AdQuartile]: LogLevel.Log,
  [ListenableEvents.AdSkipped]: LogLevel.Log,
  [ListenableEvents.AdStarted]: LogLevel.Log,
  [ListenableEvents.AirplayAvailable]: LogLevel.Log,
  [ListenableEvents.AirplayChanged]: LogLevel.Log,
  [ListenableEvents.AspectRatioChanged]: LogLevel.Log,
  [ListenableEvents.AudioAdaptation]: LogLevel.Log,
  [ListenableEvents.AudioAdded]: LogLevel.Log,
  [ListenableEvents.AudioChanged]: LogLevel.Log,
  [ListenableEvents.AudioDownloadQualityChange]: LogLevel.Log,
  [ListenableEvents.AudioDownloadQualityChanged]: LogLevel.Log,
  [ListenableEvents.AudioPlaybackQualityChanged]: LogLevel.Log,
  [ListenableEvents.AudioQualityAdded]: LogLevel.Log,
  [ListenableEvents.AudioQualityChanged]: LogLevel.Log,
  [ListenableEvents.AudioQualityRemoved]: LogLevel.Log,
  [ListenableEvents.AudioRemoved]: LogLevel.Log,
  [ListenableEvents.CastAvailable]: LogLevel.Log,
  [ListenableEvents.CastStart]: LogLevel.Log,
  [ListenableEvents.CastStarted]: LogLevel.Log,
  [ListenableEvents.CastStopped]: LogLevel.Log,
  [ListenableEvents.CastWaitingForDevice]: LogLevel.Log,
  [ListenableEvents.CueEnter]: LogLevel.Log,
  [ListenableEvents.CueExit]: LogLevel.Log,
  [ListenableEvents.CueParsed]: LogLevel.Log,
  [ListenableEvents.CueUpdate]: LogLevel.Log,
  [ListenableEvents.Destroy]: LogLevel.Log,
  [ListenableEvents.DownloadFinished]: LogLevel.Log,
  [ListenableEvents.DrmLicenseAdded]: LogLevel.Log,
  [ListenableEvents.DurationChanged]: LogLevel.Log,
  [ListenableEvents.DVRWindowExceeded]: LogLevel.Log,
  [ListenableEvents.Error]: LogLevel.Error,
  [ListenableEvents.LatencyModeChanged]: LogLevel.Log,
  [ListenableEvents.LicenseValidated]: LogLevel.Log,
  [ListenableEvents.Metadata]: LogLevel.Log,
  [ListenableEvents.MetadataChanged]: LogLevel.Log,
  [ListenableEvents.MetadataParsed]: LogLevel.Log,
  [ListenableEvents.ModuleReady]: LogLevel.Log,
  [ListenableEvents.Muted]: LogLevel.Log,
  [ListenableEvents.OverlayAdStarted]: LogLevel.Log,
  [ListenableEvents.Paused]: LogLevel.Log,
  [ListenableEvents.PeriodSwitch]: LogLevel.Log,
  [ListenableEvents.PeriodSwitched]: LogLevel.Log,
  [ListenableEvents.Play]: LogLevel.Info,
  [ListenableEvents.PlaybackFinished]: LogLevel.Info,
  [ListenableEvents.PlaybackSpeedChanged]: LogLevel.Log,
  [ListenableEvents.PlayerResized]: LogLevel.Log,
  [ListenableEvents.Playing]: LogLevel.Info,
  [ListenableEvents.Ready]: LogLevel.Info,
  [ListenableEvents.Seek]: LogLevel.Log,
  [ListenableEvents.Seeked]: LogLevel.Log,
  [ListenableEvents.SegmentPlayback]: LogLevel.Log,
  [ListenableEvents.SegmentRequestFinished]: LogLevel.Log,
  [ListenableEvents.ShowAirplayTargetPicker]: LogLevel.Log,
  [ListenableEvents.SourceLoaded]: LogLevel.Log,
  [ListenableEvents.SourceUnloaded]: LogLevel.Log,
  [ListenableEvents.StallEnded]: LogLevel.Log,
  [ListenableEvents.StallStarted]: LogLevel.Log,
  [ListenableEvents.SubtitleAdded]: LogLevel.Log,
  [ListenableEvents.SubtitleDisable]: LogLevel.Log,
  [ListenableEvents.SubtitleDisabled]: LogLevel.Log,
  [ListenableEvents.SubtitleEnable]: LogLevel.Log,
  [ListenableEvents.SubtitleEnabled]: LogLevel.Log,
  [ListenableEvents.SubtitleRemoved]: LogLevel.Log,
  [ListenableEvents.TargetLatencyChanged]: LogLevel.Log,
  [ListenableEvents.TimeChanged]: LogLevel.Log,
  [ListenableEvents.TimeShift]: LogLevel.Log,
  [ListenableEvents.TimeShifted]: LogLevel.Log,
  [ListenableEvents.Unmuted]: LogLevel.Log,
  [ListenableEvents.VideoAdaptation]: LogLevel.Log,
  [ListenableEvents.VideoDownloadQualityChange]: LogLevel.Log,
  [ListenableEvents.VideoDownloadQualityChanged]: LogLevel.Log,
  [ListenableEvents.VideoPlaybackQualityChanged]: LogLevel.Log,
  [ListenableEvents.VideoQualityAdded]: LogLevel.Log,
  [ListenableEvents.VideoQualityChanged]: LogLevel.Log,
  [ListenableEvents.VideoQualityRemoved]: LogLevel.Log,
  [ListenableEvents.ViewModeChanged]: LogLevel.Log,
  [ListenableEvents.VolumeChanged]: LogLevel.Log,
  [ListenableEvents.VRStereoChanged]: LogLevel.Log,
  [ListenableEvents.VRViewingDirectionChange]: LogLevel.Log,
  [ListenableEvents.VRViewingDirectionChanged]: LogLevel.Log,
  [ListenableEvents.Warning]: LogLevel.Warn,

  // TubEvents
  [ListenableEvents.AdBlocked]: LogLevel.Warn,
  [ListenableEvents.PlaybackConditionChanged]: LogLevel.Info,
  [ListenableEvents.YospaceAnalyticUpdate]: LogLevel.Info,
  [ListenableEvents.YospaceInitError]: LogLevel.Warn,
  [ListenableEvents.YospaceSessionError]: LogLevel.Warn,

  // LitePlayerEvents
  [ListenableEvents.AdStateChanged]: LogLevel.Log,
  [ListenableEvents.AdTimeChanged]: LogLevel.Log,
  [ListenableEvents.ContentInterrupted]: LogLevel.Log,
  [ListenableEvents.ContentLost]: LogLevel.Log,
  [ListenableEvents.MediaStarted]: LogLevel.Info,
  [ListenableEvents.MediaStateChanged]: LogLevel.Info,
  [ListenableEvents.MediaTimeChanged]: LogLevel.Log,
  [ListenableEvents.PlaybackStarted]: LogLevel.Log,
  [ListenableEvents.PlaybackEnded]: LogLevel.Log,
  [ListenableEvents.SCTE]: LogLevel.Log,
  [ListenableEvents.SCTEParsed]: LogLevel.Log,
  [ListenableEvents.TokenError]: LogLevel.Log,
  [ListenableEvents.TokenResponseReceived]: LogLevel.Log,
};

const getLogger = (logLevel: LogLevel) => {
  switch (logLevel) {
    case LogLevel.Error:
      return eventErr;
    case LogLevel.Warn:
      return eventWarn;
    case LogLevel.Info:
      return eventInfo;
  }
  return eventLog;
};

const suppressedEvents: ListenableEvents[] = [
  // noisy events
  ListenableEvents.AdTimeChanged,
  ListenableEvents.MediaTimeChanged,
  ListenableEvents.TimeChanged,
  // uninterested events
  ListenableEvents.DownloadFinished,
  ListenableEvents.SegmentPlayback,
  ListenableEvents.SegmentRequestFinished,
  // don't care about parsed, only when executed
  ListenableEvents.CueParsed,
  ListenableEvents.MetadataParsed,
  // Safari-only event (via service worker?)
  ListenableEvents.SCTEParsed,
  // "SCTE" event is a subset of "Metadata" event, ignore parent
  ListenableEvents.Metadata,
];

const listenToPlayer = (player: IPlayer, on = true) => {
  const playerFn = player[on ? 'on' : 'off'].bind(player);
  Object.entries(eventMap)
    .filter(([key]) => !suppressedEvents.includes(key as ListenableEvents))
    .forEach(([eventName, logLevel]) => {
      playerFn(eventName as ListenableEvents, getLogger(logLevel));
    });
};

@Component({
  selector: 'glass-player',
  templateUrl: './glass-player.component.html',
  styleUrls: ['./glass-player.component.css'],
  providers: [MessageService],
})
export class GlassPlayer implements OnDestroy {
  @Output() playerMessage = new EventEmitter<PlayerLogMessage>();
  @Output() mediaLoaded = new EventEmitter<any[]>();
  @Input() mediaProfiles = [];
  @Input() noMargin = false;
  @ViewChild('parentContainer') parentContainer!: ElementRef;
  config!: ConfigMap;
  currentOpts: PlayerOptions;
  containerId = '';
  player?: IPlayer;
  manualBitrate = false;
  loggingListeners: [ListenableEvents, PlayerEventListener][] = [];
  playerVersion = '';
  selectedMediaProfile = '';
  private destroyRef = inject(DestroyRef);
  constructor(
    private readonly cs: ConfigService,
    private readonly renderer: Renderer2,
  ) {
    cs.CurrentConfig$.pipe(
      takeUntilDestroyed(this.destroyRef),
      first((value) => value != null && value.config != null && value.config.key != null),
    ).subscribe((n) => (this.config = n.config));
    this.currentOpts = {
      mediaId: '',
      companyId: '',
      assetId: '',
      adProfile: '',
      env: '',
      cappedBitrate: true,
      logging: false,
    };
    this.containerId = this.generateContainerId();
  }

  generateContainerId() {
    return `pc_${Date.now()}`;
  }

  async ngOnDestroy() {
    if (this.player) {
      await this.destroyPlayer();
    }
  }

  async destroyPlayer() {
    const { player } = this;
    if (player) {
      this.detachLogging();
      try {
        await player.stop();
      } catch (e) {
        console.info('🥃 unable to stop player', e);
      }
      try {
        await player.destroy();
      } catch (e) {
        console.warn('🥃 unable to destroy player', e);
      }
    }
    this.destroyContainer();
    return true;
  }

  destroyContainer() {
    for (const element of this.parentContainer.nativeElement.querySelectorAll('.video-container')) {
      element.parentElement?.removeChild(element);
    }
  }

  async resetPlayer() {
    await this.play(this.currentOpts);
  }

  async play(opts: PlayerOptions) {
    try {
      this.currentOpts = opts;

      if (this.player) await this.destroyPlayer();

      this.createPlayerElements();

      const containerElement = document.getElementById(this.containerId) as HTMLElement;
      if (!containerElement) throw new Error('Cannot play without container element');

      const setupConfig = this.createSetupConfig();
      this.player = Player.create(containerElement, setupConfig);

      this.player.addModule(PlayerUIModule);

      this.playerVersion = this.player.version;
      this.containerId = this.generateContainerId();

      await this.player.setup();

      this.attachLogging();

      const looksLikeAUrl = this.currentOpts.mediaId?.toLowerCase().startsWith('http');

      if (looksLikeAUrl) return this.playByUrl();
      return this.playByMediaJson();
    } catch (ex) {
      console.error('🥃 failure to play():', ex);
    }
  }

  async playByMediaJson() {
    const { adProfile, cdn, companyId, env, mediaId, token } = this.currentOpts;

    if (!mediaId) throw new Error('Cannot playByMediaJson without a media ID');
    if (!this.player) throw new Error('Cannot play without a Player instance');

    const mediaData: MediaJSONData = {
      accessToken: token?.jws,
      accessTokenType: AuthTokenType.JWS,
      appId: this.config.key,
      companyId,
      env: (env ?? 'prod') as PlayerConfigEnvType,
      mediaId,
      cdnProfile: cdn,
    };

    const adConfig: AdConfig | undefined = adProfile
      ? {
          profile: adProfile,
        }
      : undefined;
    const playConfig: PlayConfig = {
      ads: adConfig,
      features: {
        tve: {
          enabled: true,
        },
      },
    };

    this.player.playByMediaJson(mediaData, playConfig);
  }

  setMediaProfile(id: string) {
    console.info(`🥃 setMediaProfile(id: ${id})`);

    this.selectedMediaProfile = id;

    // if this is our first time setting the bitrate manually we need to restart the player
    // because otherwise capBitrateToSize will override the selected profile.
    if (this.manualBitrate === false) {
      this.manualBitrate = true;
      this.currentOpts.cappedBitrate = false;

      // now when we reset the player the profile will be loaded once we receive the MediaLoaded
      // message

      this.play(this.currentOpts);
    } else {
      this.player?.setVideoQuality(id);
    }
  }

  async playByUrl() {
    const { adProfile, mediaId } = this.currentOpts;

    if (!mediaId) throw new Error('Cannot playByUrl without a URL');
    if (!this.player) throw new Error('Cannot play without a Player instance');

    const url = mediaId;

    const adConfig: AdConfig | undefined = adProfile
      ? {
          profile: adProfile,
        }
      : undefined;
    const playConfig: PlayConfig = {
      ads: adConfig,
    };

    this.player.playByUrl(url, playConfig);
  }

  detachLogging() {
    if (!this.player) return;

    console.log('🥃 detachLogging');

    try {
      // logger über alles
      listenToPlayer(this.player, false);
    } catch {
      // intentionally ignoring failures
    }

    try {
      this.loggingListeners.forEach(([event, listener]) => {
        this.player?.off(event, listener);
      });
    } catch {
      // intentionally ignoring failures
    }

    this.loggingListeners.length = 0;
  }

  attachLogging() {
    if (!this.player) return;

    console.log('🥃 attachLogging');

    // logger über alles
    listenToPlayer(this.player, true);

    this.loggingListeners.push(
      [
        ListenableEvents.Ready,
        () => {
          console.log('🥃 ready');
        },
      ],
      [
        ListenableEvents.MediaStateChanged,
        // @ts-expect-error Lite Player types aren't kosher
        ({ previousState, currentState }: MediaStateChangeEvent) => {
          console.log('🥃 mediaStateChanged', `${previousState} → ${currentState}`);
        },
      ],
      [
        ListenableEvents.SourceLoaded,
        // instead of `mediaLoaded`
        () => {
          addListenerForFirstSegment();

          if (this.manualBitrate) {
            this.player?.setVideoQuality(this.selectedMediaProfile);
          } else {
            this.mediaLoaded.emit(this.player?.getAvailableVideoQualities());
          }
        },
      ],
      [
        ListenableEvents.SCTE,
        // instead of `cueEnter`
        (scteEvent: SCTEEvent) => {
          console.log('🥃 SCTE', scteEvent.messages);
          this.playerMessage.emit({
            type: PlayerLogType.Scte,
            value: scteEvent,
          });
        },
      ],
    );

    // @ts-expect-error because I can't make TS happy
    const listenerForFirstSegment: PlayerEventListener = (segmentPlaybackEvent: SegmentPlaybackEvent) => {
      const { url } = segmentPlaybackEvent;
      if (url) {
        this.playerMessage.emit({
          type: PlayerLogType.CdnDetected,
          value: url.split('/')[2],
        });
        removeListenerForFirstSegment();
      }
    };

    const addListenerForFirstSegment = () => {
      this.loggingListeners.push([ListenableEvents.SegmentPlayback, listenerForFirstSegment]);
      this.player?.on(ListenableEvents.SegmentPlayback, listenerForFirstSegment);
    };

    const removeListenerForFirstSegment = () => {
      this.player?.off(ListenableEvents.SegmentPlayback, listenerForFirstSegment);
      this.loggingListeners = this.loggingListeners.filter(([event, listener]) => {
        return !(event === ListenableEvents.SegmentPlayback && listener === listenerForFirstSegment);
      });
    };

    this.loggingListeners.forEach(([event, listener]) => {
      this.player?.on(event, listener);
    });
  }

  createPlayerElements() {
    this.containerId = this.generateContainerId();
    const vc = document.createElement('div');
    vc.id = this.containerId;
    vc.className = 'video-container';
    this.parentContainer.nativeElement.prepend(vc);
    return true;
  }

  createSetupConfig(): SetupConfig {
    const playbackConfig: PlaybackConfig = {
      // @ts-expect-error because Lite Player didn't copy Bitmovin configs?
      autoplay: true,
      muted: true,
      volume: 50,
    };
    const engineConfig: EngineConfig = {
      key: '6f48af99-edb0-4411-8979-2c7859eddd9d',
      playback: playbackConfig,
    };
    const uiConfig: UIConfig = {
      enabled: true,
      theme: {
        accentColor: 'hsl(46deg 100% 50%)',
      },
      inactivityThreshold: 2000,
      components: {
        disable: [UIControlName.Air_Play, UIControlName.Bitrate, UIControlName.Cast, UIControlName.Fullscreen],
      },
    };
    const setupConfig: SetupConfig = {
      debug: environment?.topDebug ?? false,
      engine: engineConfig,
      ui: uiConfig,
    };
    return setupConfig;
  }
}

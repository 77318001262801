import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'event-watch-akamai-stats',
  standalone: true,
  imports: [],
  template: `
    @if (streamId) {
      <iframe [src]="getEmbed('akamai_5xx', streamId)" width="{{ graphOpts.width }}" height="{{ graphOpts.height }}" class="graph-frame" frameBorder="0"></iframe>
      <iframe [src]="getEmbed('akamai_4xx', streamId)" width="{{ graphOpts.width }}" height="{{ graphOpts.height }}" class="graph-frame" frameBorder="0"></iframe>
      <iframe [src]="getEmbed('akamai_2xx', streamId)" width="{{ graphOpts.width }}" height="{{ graphOpts.height }}" class="graph-frame" frameBorder="0"></iframe>
    }
  `,
  styleUrl: './event-watch-akamai-stats.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventWatchAkamaiStatsComponent {
  private _streamId = '';
  public get streamId(): string {
    return this._streamId;
  }
  @Input() set streamId(value: string) {
    this._streamId = value;
    this.cdr.markForCheck();
  }
  graphOpts = {
    width: 500,
    height: 200,
    legend: false,
  };
  urlDict: Record<string, string> = {
    akamai_5xx: 'ca64d80726f75c8ee82530226b8fe09275dacc2b5e104e746d4a02918c0cf45e',
    akamai_4xx: '5099588d8fc54740788c272bcd134a9690077c3e43fbb20f8aaad3dea06b071c',
    akamai_2xx: '387165296b183db7a672ebe89d9abee3c61430aa6fe8267ecb22b836074b13d2',
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {}

  getEmbed(graphName: string, streamId: string) {
    const url = `https://app.datadoghq.com/graph/embed?token=${this.urlDict[graphName]}&height=${this.graphOpts.height}&width=${this.graphOpts.width}&legend=${this.graphOpts.legend}&streamid=${streamId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
